import * as React from 'react'
import Layout from '../../../layout'
import GroupCreatedPage from '../../../pagesComponents/groups/group-created'

const GroupCreated = ({ id }) => {
	return (
		<Layout title="Group created">
			<GroupCreatedPage groupId={id} />
		</Layout>
	)
}

export default GroupCreated
