import React, { useEffect, useContext } from 'react'
import BasePage from '../../components/basePage'
import { Flex, Text, Heading, Button } from '@chakra-ui/react'
import { getTempGroup, removeTempGroup } from '../../helpers/database'
import { RWebShare } from 'react-web-share'
import { useAuth } from '../../hooks/useAuth'
import eventTracking from '../../services/eventTracking'
import { navigate } from 'gatsby'
import NestedNote from '../../components/nested-note'
import { getGroup } from '../../services/group'
import { useQuery } from '@tanstack/react-query'

const GroupCreated = ({ groupId }) => {
	const groupTemp = getTempGroup() || {}
	const { user } = useAuth()

	const groupQuery = useQuery(
		['group', groupId, { userId: user?.uid }],
		getGroup
	)

	const group = groupTemp.id ? groupTemp : groupQuery.data

	const shareUrl =
		typeof window !== 'undefined'
			? `${window.location.origin}/groups/${groupId}`
			: ''

	const data = {
		title: `Fairgame Group: ${group?.name || ''}`,
		text: `I’m inviting you to join the group “${
			group?.name || ''
		}” so we can play together at Fairgame`,
		url: shareUrl
	}

	const handleShare = async () => {
		if (typeof navigator !== 'undefined') {
			await navigator.share({
				text: `I’m inviting you to join the group “${
					group?.name || ''
				}” so we can play together at Fairgame: ${shareUrl}`
			})
		}

		eventTracking('share_group', {
			group_id: groupId,
			user_id: user?.uid
		})
	}

	const afterShare = () => {
		eventTracking('share_group', {
			group_id: groupId,

			user_id: user?.uid
		})
	}

	useEffect(() => {
		removeTempGroup()
	}, [])

	return (
		<BasePage
			theme="light"
			primaryBtn={
				<Button
					mb="50px"
					w="100%"
					variant="primary"
					onClick={() => {
						navigate(`/groups/${groupId}`)
					}}
				>
					View Group
				</Button>
			}
			showMenuBtn={true}
			tip={
				'Tip: You can edit your group name on the Groups page from the dashboard'
			}
		>
			{group?.name && (
				<NestedNote
					outerText="You're in"
					innerText={group?.name}
					outerTextColor="black"
				/>
			)}

			<Flex justifyContent="center" mt="10px">
				<Heading as="h1" color="black">
					add your <br /> teammates
				</Heading>
			</Flex>

			<Text variant="m" mt="16px" textAlign="center">
				Share your new group with your guests before you start playing!
			</Text>
		</BasePage>
	)
}
export default GroupCreated
