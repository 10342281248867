import * as React from 'react'
import { Box, Text } from '@chakra-ui/react'
import Note from './note'
import LightNote2SVG from '../icons/light-note-2.svg'

const NestedNote = (props) => {
	const {
		outerText,
		innerText,
		outerTextColor = 'rgba(255,255,255,0.7)'
	} = props
	return (
		<Box position="relative" padding={4} marginTop={4} marginBottom={6}>
			<Box
				transform="rotate(180deg)"
				width="100%"
				position="absolute"
				top={0}
				left={0}
				right={0}
				bottom={0}
			>
				<LightNote2SVG
					width="100%"
					height="100%"
					preserveAspectRatio="none"
				/>
			</Box>
			<Text position="relative" color={outerTextColor} marginBottom={1}>
				{outerText}
			</Text>
			{innerText && (
				<Note text={innerText} color="#000" backgroundOpacity={0.5} />
			)}
		</Box>
	)
}

export default NestedNote
